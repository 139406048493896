html,
body {
  padding: 0;
  margin: 0;
  background-color: #121212;
  color: rgb(249 250 251);
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

/* label {
 color: rgb(255, 255, 255, 0.9);
}

h1 {
 color: rgb(255, 255, 255, 0.9);
}

h2 {
 color: rgb(255, 255, 255, 0.9);
}

h3 {
 color: rgb(255, 255, 255, 0.9);
}

h4 {
 color: rgb(255, 255, 255, 0.9);
}

h5 {
 color: rgb(255, 255, 255, 0.9);
}

h6 {
 color: rgb(255, 255, 255, 0.9);
}

p {
 color: rgb(255, 255, 255, 0.9);
} */

* {
  box-sizing: border-box;
}
main {
  padding: 10px;
  z-index: -10;
}

/* img {
  width: auto;
  max-width: 100%;
  object-fit: cover;
} */

code {
  background: #fafafa;
  border-radius: 5px;
  padding: 0.75rem;
  font-family: Menlo, Monaco, Lucida Console, Courier New, monospace;
}

textarea {
  width: 500px;
  font-size: large;
  border-radius: 8px;
  border: 1px solid grey;
  padding-left: 8px;
}

input[type="button"] {
  border: none;
  /* background: cornflowerblue; */
  /* color: white; */
  padding: 12px 24px;
  margin: 8px;
  font-size: 24px;
  border-radius: 8px;
  cursor: pointer;
}

input {
  background-color: rgb(211, 211, 211, 1);
  border-color: rgb(211, 211, 211, 0.8);
  border-radius: 8px;
  /* color: white; */
}

footer {
  padding: 20px;
  justify-content: center;
  align-items: center;
  text-align: center;
}

button:hover {
  cursor: pointer;
}

a:hover {
  cursor: pointer;
  opacity: 0.7;
}

button:hover {
  cursor: pointer;
  opacity: 0.7;
}

@media (max-width: 750px) {
  textarea {
    width: 250px;
  }
}
