.gallery-container {
  text-align: center;
  display: flex;
  flex-direction: column;
  z-index: 1;
}

.gallery {
  border: 1px solid #ccc;
  position: relative;
  border-radius: 10px;
}

.gallery:hover {
  border: 1px solid #777;
}

.gallery img {
  width: 100%;
  height: 230px;
  border-radius: 10px 10px 0 0;
  background-size: contain;
  background-repeat: no-repeat;
}

.desc {
  padding: 15px;
  text-align: center;
  height: 50px;
  font-size: 12px;
}

.responsive {
  padding: 0 6px;
  float: left;
  width: 24.99999%;
  margin: 6px 0;
  z-index: 2;
}

.download-btnContainer {
  position: absolute;
  z-index: 500;
  right: 5px;
  bottom: 6%;
  cursor: pointer;
  z-index: 3;
}

.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

.loadMore-BtnContainer {
  height: 150px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.loadMore-BtnContainer button {
  padding: 15px 30px;
  background-color: blueviolet;
  font-size: 18px;
  border-radius: 10px;
  color: #fcf7f7;
  font-weight: bold;
}

/* media */
@media screen and (max-width: 768px) {
  .responsive {
    width: 49.99999%;
  }
}

@media screen and (max-width: 640px) {
  .responsive {
    width: 100%;
  }
}

@media screen and (max-width: 1024px) {
}

/* @media screen and (max-width: 1280px) {
}

@media screen and (max-width: 1536px) {
} */

/* @media only screen and (max-width: 500px) {
  .responsive {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .responsive {
    width: 100%;
  }
}

@media screen and (max-width: 1024px) {
  .responsive {
    width: 49%;
  }
}

@media screen and (max-width: 1280px) {
}

@media screen and (max-width: 1536px) {
} */
