.App {
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: #121212;
}

.Full-View-Height {
  height: 100vh;
}

.App-logo {
  height: 30px;
  width: auto;
  pointer-events: none;
  margin-left: 30px;
  /* background-image: url("../images/LogoHorizontal.png"); */
}

.AppBar {
  position: sticky;
  top: 0;
  overflow: hidden;
  background-color: #333;
  padding: 18px;
  width: 100%;
  z-index: 500;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
}

.AppBar-left {
  display: flex;
  align-items: center;
  justify-content: center;
}

.center-container {
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.profile-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.profile-container img {
  width: 30px;
  height: 30px;
  background-color: #167bff;
  border-radius: 50%;
  border: 1px solid rgb(251, 248, 248);
}

.profile-container p {
  font-size: 12px;
  padding: 0 8px;
  white-space: pre-line;
}

.profile-container div {
  border-left: 1px solid #fff;
  display: inline-flex;
  align-items: center;
  padding-left: 10px;
}

.App-link {
  color: #61dafb;
}

.logoTop {
  width: 50px;
  height: 60px;
}

.loginCont {
  justify-content: center;
  align-items: center;
}

.mainContainer {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  font-size: 64px;
  font-weight: bolder;
  align-items: center;
  justify-content: center;
}

.resultContainer {
  display: flex;
}

.historyItem {
  flex-direction: row;
  display: flex;
  width: 400px;
  align-items: center;
  justify-content: space-between;
}

.historyContainer {
  flex-direction: column;
  display: flex;
  height: 200px;
  align-items: center;
  flex-grow: 5;
  justify-content: flex-start;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 260px;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-bottom: 10px;
}

.inputContainer > .errorLabel {
  color: red;
  font-size: 12px;
}

.inputBox {
  height: 48px;
  width: 500px;
  font-size: large;
  border-radius: 8px;
  border: 1px solid grey;
  padding-left: 8px;
}

.logo {
  width: 250px;
  height: 60px;
  background-image: url("../images/LogoHorizontal.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  margin-left: 20px;
}

.link {
  color: rgb(255, 255, 255, 0.9);
}

.textWhite {
  color: rgb(255, 255, 255, 0.9);
}

.paddingRight5 {
  padding-right: 5px;
}

.flexCol {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.flexCol {
  display: flex;
  flex-direction: column;
}

.flexCol-Centered {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.wrapper {
  display: flex;
  flex-direction: column;
}

.auth-inner {
  background-color: rgb(211, 211, 211, 0.3);
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px;
  border-radius: 10px;
}

.auth-wrapper .form-control:focus {
  border-color: #167bff;
  box-shadow: none;
  /* margin-top: 20; */
}

.auth-wrapper h2 {
  text-align: center;
}

.loginBtn {
  height: 60px;
  width: 500px;
  font-size: large;
  border-radius: 8px;
  border: 1px solid grey;
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
}

.p {
  padding: 10px;
  display: flex;
  text-align: center;
  text-wrap: wrap;
}

.text-center {
  text-align: center;
}

.error {
  color: red;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.dropdown {
  position: relative;
}

.menu {
  position: absolute;
  list-style-type: none;
  margin: 5px auto;
  padding: 0;
  border: 1px solid grey;
  width: "100%";
  background-color: "red";
  display: table;
}

.menu > li {
  margin: 0;
  padding: 0 20px;
  background-color: white;
}

.menu > li:hover {
  background-color: lightgray;
}

.menu > li > label {
  width: 100%;
  height: 100%;
  text-align: left;
  background: none;
  color: inherit;
  border: none;
  padding: 5px;
  margin: 0;
  font: inherit;
  /* cursor: pointer; */
}

.flexCont {
  display: flex;
  justify-content: space-evenly;
}

.errorContainer {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
/* // */
@media screen and (min-width: 0px) and (max-width: 640px) {
  .App-logo {
    height: 20px;
    margin-left: 10px;
  }
  .logo {
    display: none;
  }
}

@media screen and (min-width: 641px) and (max-width: 768px) {
  .App-logo {
    height: 25px;
    margin-left: 10px;
  }
  .inputBox {
    width: 250px;
  }
  .flexCont {
    display: inline-grid;
  }

  .logo {
    width: 150px;
    height: 150px;
  }

  .loginBtn {
    width: 250px;
  }

  .p {
    padding: 20px;
  }

  .auth-inner {
    width: 350px;
    background-color: rgb(211, 211, 211, 0.2);
  }
}
@media (max-width: 1024px) {
}

@media (max-width: 1280px) {
}

@media (max-width: 1536px) {
}

/* // */
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
