.qr-code-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  position: relative;
  background-position: bottom;
  background-repeat: no-repeat;
  /* height: 100%; */
  text-align: center;
  overflow-x: hidden;
}
.qr-code-container h1 {
  font-size: 45px;
}

.qr-box {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
  gap: 30px;
  margin-top: 30px;
  overflow-x: hidden;
}

.qr-box button {
  margin: 0;
  border: 2px solid rgb(238, 227, 227);
  border-radius: 8px;
  padding: 15px;
  cursor: pointer;
  margin: 10px 0;
  background-color: rgb(45, 140, 45);
}

.qr-box button:hover {
  background-color: rgb(196, 250, 196);
}

.qr-box-item {
  display: inline-flex;
  flex-direction: column;
  margin: 10px;
}
.qr-box-item div {
  margin: 10px 0;
}
.qr-box-item a {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 10px 0px;
  background-color: rgb(61, 61, 239);
  border-radius: 10px;
  cursor: pointer;
  text-decoration: none;
  color: white;
  margin: 10px;
}

.qr-code-container p {
  text-align: center;
  /* margin-bottom: 10px; */
}

.qr-code-container img {
  width: 25px;
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .qr-box {
    display: block;
  }
}

@media screen and (min-width: 641px) and (max-width: 768px) {
  .qr-box {
    display: block;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .qr-box {
    display: block;
  }
}

@media screen and (max-width: 1280px) {
}

@media screen and (max-width: 1536px) {
}
