.record-chart-container {
  background-color: aqua;
}

.record-filter-container {
  padding: 10px;
  height: 50px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 20px;
}

.record-filter-container select {
  padding: 8px;
  border-radius: 5px;
  margin: 5px;
  width: 30%;
}

.record-filter-container button {
  background-color: #04aa6d;
  color: white;
  padding: 10px;
  margin: 5px;
  border: none;
  cursor: pointer;
  width: 10%;
  border-radius: 5px;
  opacity: 0.8;
  text-align: center;
}

.record-filter-container button:hover {
  opacity: 1;
}

.record-list-container {
  margin: 20px;
}
.table-container {
  margin: 10px 0;
}

.recordsTable-container {
  font-family: Arial, Helvetica, sans-serif;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 15px 0;
  gap: 15px;
}

.recordsTable {
  border-collapse: collapse;
  width: 100%;
}

.recordsTable td,
.recordsTable th {
  border: 1px solid #ddd;
  padding: 8px;
}

.recordsTable td,
.recordsTable th:nth-child(1) {
  width: 75%;
}
.recordsTable td:nth-child(2),
.recordsTable th:nth-child(2) {
  text-align: center;
}

.recordsTable tr:nth-child(even) {
  background-color: #908d8d;
}
.recordsTable tr:nth-child(odd) {
  background-color: #646262;
}

.recordsTable tr:hover {
  background-color: #4489e9;
}

.recordsTable th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #04aa6d;
  color: white;
}
/* // */
@media screen and (min-width: 0px) and (max-width: 640px) {
  .record-filter-container button {
    width: 20%;
  }
  .record-filter-container {
    gap: 5px;
  }
  .recordsTable-container {
    grid-template-columns: 1fr;
  }
}

@media screen and (min-width: 641px) and (max-width: 768px) {
}
@media (max-width: 1024px) {
}

@media (max-width: 1280px) {
}

@media (max-width: 1536px) {
}
