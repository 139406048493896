.navigation {
  position: relative;
  display: flex;
  align-items: center;
  height: 60px;
  width: 100%;
  padding: 0.5rem 0rem;
  background-color: #fff;
  color: black;
  box-shadow: 0 2px 2px 2px rgba(9, 9, 9, 0.23);
  padding-left: 0.5rem;
}

.brand-name {
  text-decoration: none;
  color: black;
  font-size: 1.3rem;
  margin-left: 1rem;
}

.navigation-menu {
  margin-left: auto;
}

.navigation-menu ul {
  display: flex;
  padding: 0;
}

.navigation-menu li {
  list-style-type: none;
  margin: 0 1rem;
}

.navigation-menu li a {
  text-decoration: none;
  display: block;
  width: 100%;
}

.hamburger {
  border: 0;
  height: 40px;
  width: 40px;
  padding: 0.5rem;
  border-radius: 50%;
  background-color: #283b8b;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  /* position: absolute;
 top: 80%;
 left: 25px; */
  /* margin-top: 5px; */
  /* transform: translateY(-50%); */
  /* display: none; */
}

.hamburger:hover {
  background-color: #2642af;
}

.sidebar {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: rgb(74, 73, 73);
  overflow-x: hidden;
  margin-top: 60px;
}

.sidebar a {
  padding: 10px 16px;
  display: flex;
  align-items: center;
  border-bottom: 0.1px solid rgba(116, 115, 115, 0.6);
  text-decoration: none;
  color: #ecf2ef;
}

.sidebar p {
  margin: 0;
  padding-left: 10px;
  font-size: 16px;
}

.sidebar a:hover {
  background-color: rgb(116, 115, 115);
  color: #ffffff;
}

@media screen and (max-height: 450px) {
  .sidebar {
    padding-top: 15px;
  }
  .sidebar a {
    font-size: 18px;
  }
}

@media screen and (max-width: 750px) {
  .navigation-menu ul {
    flex-direction: column;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    height: calc(100vh - 60px);
    background-color: white;
    border-top: 1px solid black;
    display: none;
    justify-content: baseline;
  }

  .navigation-menu li {
    text-align: start;
    margin: 0;
    padding-left: 15px;
    align-items: center;
  }

  .navigation-menu li a {
    color: black;
    width: 100%;
    padding: 1rem 0;
  }

  .navigation-menu li:hover {
    background-color: #eee;
  }

  .navigation-menu.expanded ul {
    display: block;
  }

  .navButton {
    align-items: center;
    background-color: #2642af;
    align-content: center;
    color: #283b8b;
    font-size: 10px;
  }

  .button {
    background-color: #04aa6d;
    /* Green */
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
  }

  .sidebarCont {
    background-color: #121212;
  }
}
